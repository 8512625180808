<template>
    <v-container fluid fill-height class="py-0">
        <v-row justify="center">
            <v-card width="100vw" min-height="100vh" class="pt-10">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title>
                        <v-row align="center" justify="center">
                            <v-col
                                cols="8"
                                md="6"
                                sm="8"
                                class="text-md-h2 font-weight-bold text-sm-h4"
                            >
                                <center>Add New Achievements</center>
                                <v-divider></v-divider>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" class="mx-0 px-0" justify="center">
                            <v-col class="3" md="2" sm="2">
                                <v-text-field
                                    :value="year"
                                    placeholder="Year"
                                    @change="(v) => (year = v)"
                                    required
                                    outlined
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5" sm="5">
                                <v-btn
                                    class="mb-10"
                                    color="orange lighten-2"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="addAttachment"
                                    style="float: left"
                                >
                                    <v-icon dark left> mdi-cloud-upload </v-icon> Image
                                </v-btn>
                                <input
                                    ref="uploadImg"
                                    class="d-none"
                                    type="file"
                                    accept="image/*"
                                    @change="onFileChanged"
                                />
                                <div>
                                    <a
                                        :href="imgLink"
                                        class="px-5 text-button mb-10"
                                        target="_blank"
                                        >{{ img }}</a
                                    >
                                </div>
                            </v-col>
                        </v-row>
                        <v-row
                            align="center"
                            justify="center"
                            v-for="(data, counter) in achievements"
                            v-bind:key="counter"
                            class="mx-0 px-0"
                        >
                            <v-col cols="4" md="2" sm="2">
                                <v-text-field
                                    v-model="data.icon"
                                    placeholder="Icon"
                                    required
                                    outlined
                                    :append-icon="data.icon"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="8" md="5" sm="5">
                                <v-text-field
                                    v-model="data.text"
                                    placeholder="Achievement Detail"
                                    required
                                    outlined
                                    :append-outer-icon="
                                        counter > 0 ? 'mdi-trash-can' : 'mdi-plus-circle'
                                    "
                                    @click:append-outer="HandleForm(counter > 0 ? counter : 'add')"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="9" sm="9" align="right">
                                <v-btn class="" color="primary lighten-2" @click="saveData()">
                                    <v-icon dark left> mdi-content-save </v-icon> Submit
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-form>
            </v-card>
            <div>
                <v-btn fab class="red white--text" fixed @click="$router.go(-1)" right top>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
export default {
    name: "AchievementForm",
    data: () => ({
        loading: false,
        valid: true,
        year: "",
        img: "",
        imgLink: "",
        patch: false,
        achievements: [
            {
                icon: "",
                text: ""
            }
        ]
    }),
    methods: {
        HandleForm(data) {
            if (data == "add") {
                this.achievements.push({
                    icon: "",
                    text: ""
                });
            } else {
                this.achievements.splice(data, 1);
            }
        },
        addAttachment() {
            this.$refs.uploadImg.click();
        },
        async upload(formData) {
            this.$toast.info("Uploading the File...");
            let resp = await axios.post("/api/upload", formData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            let respJson;
            if (resp.status == 200) {
                this.$toast.success("File Uploaded");
                respJson = resp.data;
            } else {
                this.$toast.error("Failed to Upload");
            }
            return respJson;
        },
        async onFileChanged(e) {
            this.loading = true;
            let selectedFile = e.target.files[0];
            let fd = new FormData();
            fd.append("file", selectedFile);
            let resp = await this.upload(fd);
            if (resp.success) {
                this.imgLink = "/" + encodeURI(resp.path);
                this.img = selectedFile.name;
            }
            this.loading = false;
        },
        async saveData() {
            this.$toast.info("Saving the Achievement...");
            let itemsb64 = btoa(JSON.stringify(this.achievements));
            let jsonData = {
                Img: this.img,
                Year: parseInt(this.year),
                Items: itemsb64
            };
            try {
                let response;
                if (this.patch) {
                    response = await axios.patch("/api/achievement", jsonData, {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                } else {
                    response = await axios.put("/api/achievement", jsonData, {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                }

                if (response.status == 201) {
                    this.$toast.success("Achievement Added", {
                        onClose: () => {
                            this.$router.push({ path: "/about" });
                        }
                    });
                } else if (response.status == 204 && this.$route.params.id) {
                    this.$toast.success("Achievement Edited", {
                        onClose: () => {
                            this.$router.push({ path: "/about" });
                        }
                    });
                } else {
                    this.$toast.error("Oh Shit! Failed! :(");
                }
            } catch (error) {
                this.$toast.error("Oh Shit! Failed! :(");
            }
        }
    },
    async created() {
        if (this.$route.params.id) {
            this.patch = true;
            let year = this.$route.params.id;
            let achievementDetail = await axios.get("/api/achievement/" + year);
            this.year = achievementDetail.data.Year;
            this.achievements = JSON.parse(atob(achievementDetail.data.Items));
            this.img = achievementDetail.data.Img;
            this.imgLink = "/assets/img/" + this.img;
        }
    }
};
</script>

<style scoped>
.v-text-field >>> input {
    font-size: 1.2em;
}
</style>
